import React from "react";
// import { Routes, Route } from 'react-router-dom';
import Home from "./Containers/Home";
import Navbar from "./Components/Navbar";
import "./App.css";

function App() {
      return (
            <div className="App">
                  {/* navbarcontent */}
                  {/* importing navbar */}
                  <Navbar />
                  {/* sections */}
                  <Home />
            </div>
      );
}

export default App;
