import React from "react";
import { useSpring, animated, config } from "react-spring";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import { TiHome } from "react-icons/ti";
import "./style.scss";

const Home = () => {
      const customColor = "#4D6457";
      const fadeIn = useSpring({
            opacity: 1,
            from: { opacity: 0 },
      });
      const imageStyle = {
            maxWidth: "100%",
            maxHeight: "100%",
      };

      const textAnimation = useSpring({
            from: { opacity: 0, transform: "translateY(-30px)" },
            to: { opacity: 1, transform: "translateY(0px)" },
            config: config.wobbly,
            delay: 800,
      });

      const imageAnimation = useSpring({
            from: { opacity: 0, transform: "scale(0.8)" },
            to: { opacity: 1, transform: "scale(1)" },
            config: config.wobbly,
            delay: 100,
      });

      return (
            <>
                  <div id="home" className="container">
                        <div className="content">
                              <div className="text">
                                    <animated.div style={textAnimation}>
                                          <h1
                                                className="text-7xl font-extrabold whitespace-pre-line leading-hero mt-4 mb-8 w-100"
                                                style={{
                                                      textAlign: "left",
                                                      marginLeft: "1%",
                                                }}
                                          >
                                                Exciting Things
                                                <br />
                                                <span
                                                      style={{
                                                            color: customColor,
                                                      }}
                                                >
                                                      {" "}
                                                      Coming Soon...
                                                </span>
                                          </h1>
                                    </animated.div>
                                    <animated.div
                                          style={fadeIn}
                                          className="text-center my-8 contact__details"
                                    >
                                          <p className="mb-2 flex items-center text-xl">
                                                <FaEnvelope className="mr-2" />
                                                <span>info@hidako.com</span>
                                          </p>
                                          <p className="mb-2 flex items-center text-xl">
                                                <FaPhone className="mr-2" />
                                                <span>
                                                      8766601888, 8562836285
                                                </span>
                                          </p>
                                          <p className="mb-2 flex items-center text-xl">
                                                <TiHome className="mr-2" />
                                                <span>
                                                      357, Agrasen path,
                                                      officers campus main,
                                                      sirsi road, Jaipur-302012
                                                </span>
                                          </p>
                                    </animated.div>
                              </div>
                              <animated.div style={{ ...imageAnimation }}>
                                    <div
                                          className={`home_image1 bg-${customColor.slice(
                                                1
                                          )} rounded-full p-4`}
                                    >
                                          <img
                                                src="image2.svg"
                                                alt="agency img"
                                                style={{
                                                      ...imageStyle,
                                                      ...imageAnimation,
                                                }}
                                          />
                                    </div>
                              </animated.div>
                        </div>
                  </div>
                  {/* <div className="footertxt">
                        Website Design & Develop by{" "}
                        <a
                              href="https://infinityinfosys.com"
                              target="_blank"
                              class="addcolor"
                        >
                              Infinity Infosys Pvt Ltd
                        </a>
                  </div> */}
            </>
      );
};

export default Home;
