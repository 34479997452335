import React, { useState } from "react";
import { FaBars, FaFacebook, FaInstagram } from "react-icons/fa";
import { HiX } from "react-icons/hi";
import { Link } from "react-router-dom";
import "./style.scss";

const data = [
      {
            label: "Facebook",
            to: "https://www.facebook.com",
            icon: <FaFacebook />,
      },
      {
            label: "Instagram",
            to: "https://www.instagram.com",
            icon: <FaInstagram style={{ color: "#C11E6B" }} />,
      },
];

const Navbar = () => {
      const [activeLink, setActiveLink] = useState(null);
      const [toggleIcon, setToggleIcon] = useState(false);

      const handleToggleIcon = () => {
            setToggleIcon(!toggleIcon);
      };

      return (
            <nav className="navbar container-lg">
                  <div className="navbar_container">
                        <Link
                              to={"/"}
                              className="navbar_container_logo text-5xl font-bold"
                        >
                              <img src="/logo.png" alt="" />
                        </Link>
                        <ul
                              className={`navbar_container_menu ${
                                    toggleIcon ? "active" : ""
                              }`}
                        >
                              {data.map((item, index) => (
                                    <li
                                          key={index}
                                          className={`navbar_container_menu_item ${
                                                activeLink === index
                                                      ? "active"
                                                      : ""
                                          }`}
                                          onClick={() => setActiveLink(index)}
                                    >
                                          <Link
                                                className="navbar_container_menu_item_links"
                                                to={item.to}
                                                target={
                                                      item.label !== "Home"
                                                            ? "_blank"
                                                            : ""
                                                }
                                                rel={
                                                      item.label !== "Home"
                                                            ? "noopener noreferrer"
                                                            : ""
                                                }
                                          >
                                                {item.label === "Home" ? (
                                                      item.label
                                                ) : (
                                                      <span>{item.icon}</span>
                                                )}
                                          </Link>
                                    </li>
                              ))}
                        </ul>
                        <div className="nav-icon" onClick={handleToggleIcon}>
                              {toggleIcon ? (
                                    <HiX size={50} />
                              ) : (
                                    <FaBars size={50} />
                              )}
                        </div>
                  </div>
            </nav>
      );
};

export default Navbar;
